.expense_row{
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.loss-calc {
  min-height: 600px;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  margin-top: 100px;
}

.loss-calc.mobile {
  min-height: 400px;
  -webkit-filter: none;
  -moz-filter: none;
  -o-filter: none;
  -ms-filter: none;
  filter: none;
}

.loss-calc-done {
  min-height: 600px;
}

.section-header {
  font-size: 3em !important;
  margin-bottom: 30px;
}

.section-header.mobile {
  font-size: 3em !important;
  margin-bottom: 30px;
}