.heading-logo-desktop {
  height: 220px;
  padding: 10px;
  margin: -150px auto 40px auto;
}

.heading-logo-mobile {
  height: 110px;
  padding: 10px;
  margin: -110px auto 30px auto;
}