.modal {
  text-align: center !important;
  position: relative;
}

.modal.mobile {
  /*margin-top: -25% !important;*/
  position: absolute;
  top: 8%;
}

.modal-header {
  height: 90px;
  padding-top: 10px !important;
}

#loss-calc-header.modal-header.mobile {
  height: 75px;
  padding: 10px !important;
}

.ui.progress {
  margin-top: 10px !important;
}


.ui.progress.mobile {
  margin-top: -5px !important;
}

.modal-initial-heading {
  font-size: .8em;
  margin-top: 18px !important;
}

.modal-progress-container {
  font-size: .7em !important;
  font-weight: normal !important;
  text-align: center;
}

.modal-progress-bar-completed {
  background-color: #008066 !important;
}

.modal-content {
  min-height: 450px;
  position: relative;
  vertical-align: middle;
}

.modal-content.mobile {
  min-height: 220px;
}
.modal-content.mobile.no-input {
  min-height: 350px;
}

.modal-description {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 90% !important;
  height: 100%;
  min-width: 350px !important;
}

.modal-description.mobile {
  /*width: 90% !important;*/
  width: 300px !important;
}

.modal-question {
  display: block;
  font-size: 1.5em;
  margin-bottom: 60px;
  margin-top: 60px;
}
.modal-question.mobile {
  margin-top: 0px;
  margin-bottom: 15px;
}

.modal-input-container {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  /*width: 100%;*/
}
.modal-input-container.mobile {
  width: 90%;
}


.modal-question-description {
  margin-top: 30px;
}

.progress-button-container {
  text-align: center;
  position: absolute;
  left: calc(50% - 120px);
  bottom: 15px;
}

.progress-button-container.mobile {
  bottom: 10px;
}
.back-button-spacer {
  width: 55px;
  height: 5px;
  display: inline-block;
}

.back-button {
  margin: 10px !important;
  width: 110px;
}

.back-button.mobile {
  width: 100px;
}

.next-button.mobile {
  width: 100px;
}

.next-button {
  background-color: #008066 !important;
  color: white !important;
  margin: 10px !important;
  width: 110px; 
}

.ui.dimmer {
  top: 82px !important;
}

.ui.dimmer.mobile {
  top: 60px !important;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}