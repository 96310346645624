.result-container {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  position: absolute;
  font-family: PlayfairDisplay-Black;
}

.page-container {
  padding: 30px;
  height: 1120px;
  padding-top: 70px;
  position: relative;
}

.heading {
  font-size: 36pt;
  margin: 300px auto 400px auto !important;
  text-align: center;
  font-family: PlayfairDisplay-Black;
  font-weight: normal;
}

.heading-logo {
  width: 150px;
  margin: 30px auto;
  display: block;
}

.heading-signature {
  text-align: center;
  font-size: 18pt;
  margin-bottom: 120px !important;
}

.page-footer {
  text-align: center;
  position: absolute;
  left: calc(50% - 322px);
  bottom: 10px;
}

.item-heading {
  margin-top: 50px;
  /*font-size: 20pt;*/
}

.service-list {
  list-style: none;
  font-size: 14pt;
}

.service-list-text {
  font-size: 11pt;
  margin-top: 6px;
  margin-bottom: 12px !important;
}

.service-list-item:before {
  content: "";  
  border-color: transparent #008066;  
  border-style: solid;  
  border-width: 0.35em 0 0.35em 0.45em;  
  display: block;  
  height: 0;  
  width: 0;  
  left: -1em;  
  top: 0.9em;  
  position: relative;
}