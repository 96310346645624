.header-menu-logo-desktop {
  height: 60px;
  margin: 10px 40px;
  z-index: 9999;
}

.item {
  color: white !important;
  font-size: 1.2em !important;
}

.calc-header-menu {
  -webkit-filter: none !important;
  -moz-filter: none !important;
  -o-filter: none !important;
  -ms-filter: none !important;
  filter: none !important; 
}