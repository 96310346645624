#wrapper {
  height: 100%;
  width: 100%;
  margin-top: 30px
}

#meter{
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
}

#meter-header{
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 100%;
}

.outline, #mask {
  stroke: #F1F1F1;
  stroke-width: 32;
  -webkit-transition: 3s ease-in-out;
  -moz-transition: 3s ease-in-out;
  -o-transition: 3s ease-in-out;
  transition: 3s ease-in-out;
}
.range {
  stroke-width: 60;
}
#slider, #lbl {
  position: absolute;
}
#slider {
  cursor: pointer;
  left: 0;
  margin: auto;
  right: 0;
  top: 70%;
  width: 94%;
  display: none;
}
#lbl {
  background-color: #4B4C51;
  border-radius: 2px;
  color: white;
  font-family: 'courier new';
  font-size: 25pt;
  font-weight: bold;
  padding: 4px 4px 2px 4px;
  width: 90px;
  right: calc(50% - 45px);
  top: 80%;
}
#lbl.mobile {
  width: 50px;
  font-size: 18pt;
  right: calc(50% - 25px);
  top: 70%;
}
#needle {
  height: 43%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 24%;
  transform-origin: 50% 90%;
  /*orientation fix*/
  transform: rotate(270deg);
  -webkit-transition: 3s ease-in-out;
  -moz-transition: 3s ease-in-out;
  -o-transition: 3s ease-in-out;
  transition: 3s ease-in-out;
}