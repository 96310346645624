.header-menu-logo-mobile {
  height: 40px;
}

.mobile-item {
  color: #666666 !important
}

.logo-link-container {
  margin: 10px auto;
  padding-right: 50px;
}